







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop(Boolean) readonly white!: boolean;
  @Prop(Boolean) readonly link!: boolean;
  @Prop(Boolean) readonly noType!: boolean;

  get logoFileName(): string {
    if (this.white) return "ninety-nine-logo-white.svg";
    else if (this.noType) return "logo-no-type.svg";
    else return "ninety-nine-logo.svg";
  }

  getLogo(): File {
    return require("../../assets/img/" + this.logoFileName);
  }
}
