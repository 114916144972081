

























































import { auth, formatEmailOrPhone, localStorageKeys } from "@/core";
import { MyProfileUseCases } from "@/core/MyProfile/MyProfileUseCases";
import { identifyUser } from "@/core/plugins/TrackingPlugin";
import { Component, Vue } from "vue-property-decorator";
import NinetyNineLogo from "../components/base/NinetyNineLogo.vue";

@Component({
  components: {
    NinetyNineLogo,
  },
})
export default class LogInView extends Vue {
  emailOrPhone = "";
  password = "";
  errorMsg = "";
  loading = false;

  mounted(): void {
    this.checkExpiredSession();

    if (window.location.host.includes("localhost")) {
      this.emailOrPhone = process.env.VUE_APP_LOGIN_EMAIL || "";
      this.password = process.env.VUE_APP_LOGIN_PASSWORD || "";
    }
  }

  get loginCreds(): Record<string, string> {
    return {
      emailOrPhone: this.formattedEmailOrPhone,
      password: this.password,
    };
  }

  get formattedEmailOrPhone(): string {
    return formatEmailOrPhone(this.emailOrPhone);
  }

  checkExpiredSession(): void {
    // Logic to set the SESSION_EXPIRED attribute happens in "/src/core/base/RestRepository.ts"
    if (window.localStorage.getItem(localStorageKeys.sessionExpired)) {
      this.$store.dispatch(
        "modal/setComponent",
        () => import("@/components/login/SesionExpiredModal.vue")
      );
      this.$store.dispatch("modal/show");
      window.localStorage.removeItem(localStorageKeys.sessionExpired);
    }
  }

  async login(): Promise<void> {
    this.loading = true;
    try {
      if (this.emailOrPhone.length === 0) {
        throw new Error("Email or phone is required");
      }
      if (this.password.length === 0) {
        throw new Error("Password is required");
      }
      await this.$store.dispatch("user/login", this.loginCreds);
      const client = await MyProfileUseCases.fetchClient(auth.clientId);

      identifyUser(auth.user.id);
      if (client.level !== "STANDARD") {
        this.$router.push({ name: "continue-to-app" });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    } catch (error) {
      console.error("Error logging in: ", error);
      this.errorMsg = this.$t("LogIn_Error_Message") as string;
    } finally {
      this.loading = false;
    }
  }
}
